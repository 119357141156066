import React from 'react'
import Layout from '~/layouts/layout'
import SEO from '~/components/seo'

const materialen = () => {
  return (
    <Layout language="nl" en="materials" nl="materialen">
      <SEO
        title="Materialen"
        keywords={[`valta`, `lamp`, `licht`, `materialen`]}
      />
      <div className="articleHero gridFullWidth">
        <h1>Materialen</h1>
      </div>
      <div className="centerTitleBlock gridFullWidth">
        <h3>Gemaakt van natuurlijke producten</h3>
        <p>
          Onze producten worden gemaakt met polymeren op basis van planten zoals
          maïs, suikerbiet en suikerriet.
        </p>
      </div>
      <div className="gridFullWidth materialsTwoSplit">
        <img src={`${require('~/images/materials-3.jpg')}`} loading="lazy" />
        <img src={`${require('~/images/materials-2.jpg')}`} loading="lazy" />
      </div>
      <div className="grid5"></div>
      <div
        className="articleHero gridFullWidth"
        style={{
          justifyContent: `center`,
          backgroundColor: `var(--light-grey)`,
        }}
      >
        <h2>PLA</h2>
      </div>

      <div className="grid4 materialBlock">
        <h3>3D-geprint materiaal</h3>
        <img src={`${require('~/images/3dprinter.jpg')}`} loading="lazy" />
        <p>Met de nieuwste technologiëen</p>
      </div>
      <div className="grid4 materialBlock">
        <h3>Op plantaardige basis</h3>
        <img src={`${require('~/images/corn.jpg')}`} loading="lazy" />
        <p>Gemaakt van plantaardige bronnen, zoals maïs</p>
      </div>
      <div className="grid4 materialBlock">
        <h3>Milieuvriendelijk</h3>
        <img src={`${require('~/images/filament.jpg')}`} loading="lazy" />
        <p>PLA is een milieuvriendelijk en composteerbaar soort plastic</p>
      </div>
      <div
        className="articleHero gridFullWidth"
        style={{
          justifyContent: `center`,
          backgroundColor: `var(--light-grey)`,
        }}
      >
        <h2>Eikenhout</h2>
      </div>
      <div className="grid4 materialBlock">
        <h3>Lokaal gekocht</h3>
        <img src={`${require('~/images/sawmill.jpg')}`} loading="lazy" />
        <p>Van een lokale Groningse houtzagerij</p>
      </div>
      <div className="grid4 materialBlock">
        <h3>Europees Wit Eiken</h3>
        <img src={`${require('~/images/eik.jpg')}`} loading="lazy" />
        <p>We kiezen zelf de beste ruwe planken uit</p>
      </div>
      <div className="grid4 materialBlock">
        <h3>Geschaafd en geolied</h3>
        <img src={`${require('~/images/oak.jpg')}`} loading="lazy" />
        <p>
          We zorgen ervoor dat elke plank mooi glad geschaafd en geolied wordt
        </p>
      </div>
    </Layout>
  )
}

export default materialen
